function $1(props) {
    return (
        <ul>

            <li>
                <a href="">Literature Analysis on Ontologies Applied in Clinical Decision Support Systems</a>
                <p>Jing X, Min H, Gong Y, Biondich P, Robinson D, Law T, Nohr CG, Faxvaag A, Rennert L, Hubig N, Gimbel R</p>
                <p>Studies in Health Technology and Informatics, 2024, 310: 1347-1348, https://ebooks.iospress.nl/doi/10.3233/SHTI231188</p>
            </li>
            <li>
                <a href="">Using Semantic Web Technology to leverage interoperable clinical decision support system rules: a pathway to interoperable patient records</a>
                <p>Jing X, Cimino JJ, Sittig DF, Min H, Gong Y, Boyce RD, Robinson D, Biondich P, Wright A, Nohr CG, Law T, Faxvaag A, Hubig N, Goli R, Nedunchezhiyan K, Boobalan AS, Orlioglu S, Crozier C, Rennert L, Gimbel R</p>
                <p>BMC Proceedings, 2023, 17(Suppl 19):O13</p>
            </li>
            <li>
                <a href="">Fine-tuning a Hierarchical Attention Based BiLSTM-CRF Model Using Minimal Labeled Data</a>
                <p>Komatineni K*, Goli R*, Alluri S*, Hubig NC, Min H, Gong Y, Sittig DF, Robinson D, Biondich P, Wright A, Nøhr C, Law T, Faxvaag A, Gimbel R, Rennert L, Jing X</p>
                <p>AMIA Summit 2024,pages 760-761, Mar 18-21, Boston</p>
            </li>
            <li>
                <a href="">Developing a Reusable Clinical Decision Support System Module for Immunization Recommendations: A Case Study with OpenEMR and OpenMRS</a>
                <p>Boobalan AS*, Orlioglu S*, Boyce RD, Min H, Gong Y, Sittig DF, Biondich P, Wright A, Nøhr C, Law T, Robinson D, Faxvaag A, Hubig NC, Gimbel R, Rennert L, Jing X. </p>
                <p>AMIA Summit 2024, pages 799-800, Mar 18-21, Boston</p>
            </li>
            <li>
                <a href="">Keyphrase Identification Using Minimal Labeled Data with Hierarchical Context and Transfer Learning</a>
                <p>Goli R*, Hubig N, Min H, Gong Y, Sittig DF, Rennert L, Robinson D, Biondich P, Wright A, Nøhr C, Law T, Faxvaag A, Weaver A*, Gimbel R, Jing X</p>
                <p>MedRxiv Preprint. 2023. PMID: 37292830; PMCID: PMC10246160; DOI: 10.1101/2023.01.26.23285060</p>
            </li>
            <li>
                <a href="">Ontologies Applied in Clinical Decision Support System Rules: Systematic Review</a>
                <p>Jing X, Min H, Gong Y, Biondich P, Robinson D, Law T, Nøhr C, Faxvaag A, Rennert L, Hubig N, Gimbel R</p>
                <p>JMIR Med Inform, http://dx.doi.org/10.2196/43053; PMID:36534739; PMCID:9896360</p>
            </li>
            <li>
                <a href="">Keyphrase identification with limited labeled dataset using deep active learning and domain adaptation</a>
                <p>Goli R*, Hubig N, Min H, Gong Y, Sittig DF, Robinson D, Biondich P, Wright A, Nøhr C, Law T, Faxvaag A, Gimbel R, Rennert L, Jing X</p>
                <p>AMIA 2022, Nov 5-9, 2022, Washington DC</p>
            </li>
            <li>
                <a href="">Keyphrase identification with limited labeled dataset using deep active learning and domain adaptation</a>
                <p>Goli R*, Hubig N, Renert L, Gimbel R, Jing X</p>
                <p>The 7th Annual Clemson Research Symposium, Clemson, SC, 2022-05-10</p>
            </li>
            <li>
                <a href="">A clinical decision support system (CDSS) ontology to facilitate portable vaccination CDSS rules: preliminary results</a>
                <p>Jing X, Hua M, Gong Y, Cimino JJ, Robinson D, Sittig D, Biondich P, Wright A, Nøhr C, Law T, Faxvaag A, Indani A, Hubig N, Gimbel R, Rennert L</p>
                <p>AMIA Annu Symp Proc. 2022 Feb 21;2021:1695</p>
            </li>
            
        </ul>
    );
}

export default $1;