function $1(props) {
    return (
        <ul>

            <li>
                <a href="">Process, challenges, and lessons learned in converting CDC-recommended vaccination schedules—from human-readable to machine-processable</a>
                <p>Jing X, Min H, Gong Y, Robinson D, Sittig D, Biondich P, Wright A, Nøhr C, Law T, Faxvaag A, Boyce R, Hubig N, Gimbel R, Rennert L</p>
                <p>AMIA Clinical Informatics Conference 2024, May 21-23, Minneapolis, MN</p>
            </li>
            <li>
                <a href="">Developing computable clinical decision support rules from CDC vaccination recommendations—how many mediate layers are needed? </a>
                <p>Jing X, Boyce R, Min H, Gong Y, Sittig DF, et al</p>
                <p>MCBK 2023, Oct 3-4, 2023. Virtual Conference</p>
            </li>
            <li>
                <a href="">Developing a common clinical decision system module for immunization recommendations</a>
                <p>Boobalan AS*, Orlioglu S*, Boyce RD, Min H, Gong Y, Nedunchezhiyan K*, Crozier C*, Goli R*, Sittig DF, Robinson D, Biondich P, Wright A, Nohr C, Law T, Faxvaag A, Hubig N, Rennert L, Gimbel, R, Jing X</p>
                <p>Clemson University Annual Research Symposium. May 10, 2023, Clemson, SC</p>
            </li>
            <li>
                <a href="">Interoperable clinical decision support system rules: work in progress</a>
                <p>Jing X, et al</p>
                <p>Guest lecture for the University of Pittsburgh, School of Medicine, Department of Biomedical Informatics. 2024-04-19</p>
            </li>
        </ul>
    );
}

export default $1;